import mainStyles from "./main.scss";
import { useEffect, useRef } from 'react';
import Links from "./page/Links";

function App() {


  return (
    <Links />
  );
}

export default App;
