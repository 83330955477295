import React, { useEffect, useRef } from 'react';
import linksStyles from "./Links.styles.scss";

const docpupLink = [
  {
    title: "Página web",
    link: "https://www.docpup.cl/",
    icon: null
  },
  {
    title: "Blogs",
    link: "https://www.docpup.cl/blog/",
    icon: null
  },
  {
    title: "Agenda una hora",
    link: "https://www.docpup.cl/#seccion-agenda",
    icon: null
  },
]


function Links() {



  const mainRef = useRef();
  useEffect(() => {
    const setMinHeight = () => {
      if (!mainRef.current) return;
      mainRef.current.style.minHeight = `${window.innerHeight}px`;
    };
    setMinHeight();
    window.addEventListener("resize", setMinHeight);
    return () => {
      window.removeEventListener("resize", setMinHeight);
    };
  }, []);

  return (
    <>
      <main ref={mainRef}>
        <section id="title-logo">
          <div className='logo-wrapper'>
            <img src="https://www.docpup.cl/wp-content/uploads/2021/08/cropped-docpup-logo-2.png" />
          </div>
          
          <h2>Docpup</h2>
          <p>Servicios veterinarios</p>
        </section>

        <section id="links">
          <div className="grid">
            {docpupLink && docpupLink.length > 0 && docpupLink.map((item, index) => {

              return (
                <div className='docpup-link' key={index}>
                  <a href={item.link}> {item.title}</a>
                </div>
              )
            })}
          </div>
        </section>
      </main>
    </>
  );
}

export default Links; 